import Header from "../views/HeaderView"
import Home from "../views/HomeView"
import Project from "../views/ProjectView"
import Level from "../views/LevelView"

export default [
  {
    path: '/projects',
    name: 'Home',
    components: {
      default: Home,
      header: Header
    }
  },
  {
    path: '/projects/:projectId',
    name: 'ProjectView',
    components: {
      default: Project,
      header: Header
    },
    props: true
  },
  {
    path: '/projects/:projectId/level/:levelNumber',
    name: 'LevelView',
    components: {
      default: Level,
      header: Header
    },
    props: true
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: '/projects' },
  // { path: '/about', component: About },
]
