<template>
  <div class="container pt-4 pb-4">
    <div class="row">
      <div class="col-12 col-sm-4" v-for="object in objects" :key="object.id">
        <router-link :to="{ name: 'ProjectView', params: { projectId: object.id }}" class="development-object-item">
          <div class="image-wrapper">
            <img :src="(object.media || {}).url" class="img-fluid" alt="project image"
                 :title="(object.translated || {}).name">
          </div>
          <div class="object-title">{{ (object.translated || {}).name }}</div>
          <span class="object-link">Квартир у продажі - {{ getObjectApartments(object.id).length }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: 'HomeView',

  components: {
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapState([
      'objects'
    ]),

    ...mapGetters([
      'getObjectApartments'
    ])
  },

  watch: {
  },

  created() {
  },

  methods: {
  },
};
</script>

<style lang="scss">
.development-object-item .object-link {
  text-align: center;
  opacity: 1;
  text-decoration: none;

  &:hover {
    color: #ffffff;
  }
}
</style>
