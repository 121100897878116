<template>
  <div class="kr-developing-content" :class="{ opened: active }">
    <router-view name="header" />
    <div id="kr-developing-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      active: false
    };
  },

  created() {
    this.emitter.on('unmount', val => {
      this.active = !val;
    });

    window.krDevelopingSpaOpts && this.$store.commit('setSnippets', window.krDevelopingSpaOpts.snippets)

    window.krDevelopingSpaOpts && this.$store.dispatch('loadData', {
      url: window.krDevelopingSpaOpts.dataUrl
    })
  },

  mounted() {
    setTimeout(() => {
      this.active = true
    }, 300)
  },

  methods: {
  },

  watch: {
    active(val) {
      val
          ? document.documentElement.classList.add('no-scroll')
          : document.documentElement.classList.remove('no-scroll')
    }
  }
};
</script>

<style lang="scss">
#kr-developing-content {
  width: 100vw;
  height: calc(100vh - 64px);
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 64px;
  overflow: auto;
}

.kr-developing-content {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 1000;
  transition: left .8s ease-in-out;
  overflow: hidden;
  background: #f5f5f5;

  &.opened {
    left: 0;
  }
}
</style>
