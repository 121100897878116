<template>
  <div class="d-flex flex-row justify-content-between align-items-center" id="kr-developing-header">
    <a href="#" class="btn btn-link left" @click.prevent="$router.back()">
      <span class="kr-icon"><font-awesome-icon :icon="['fas', 'arrow-turn-down']" rotation="90" size="2x" pull="left" /></span>
      <span class="d-none d-lg-block">{{ trans('header.back') }}</span>
    </a>


    <div class="kr-developing-title">
      {{ getTitle }}
      <p>{{ getSubTitle }}</p>
    </div>

    <a href="#" class="btn btn-link right" @click.prevent="close">
      <span class="d-none d-lg-block">{{ trans('header.exit') }}</span>

      <span class="kr-icon"><font-awesome-icon :icon="['fas', 'xmark']" size="2x" /></span>
    </a>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowTurnDown } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from "vuex";

library.add(faArrowTurnDown, faXmark)

export default {
  name: 'HeaderView',

  props: ['projectId', 'levelNumber'],

  components: {
  },

  data() {
    return {
    };
  },

  computed: {
    getTitle() {
      return this.projectId
          ? ((this.getObject(this.projectId) || {}).translated || {}).name
          : this.trans('header.selectProject')
    },

    getSubTitle() {
      return this.projectId && !this.levelNumber
          ? this.trans('header.selectLevel')
          : ''
    },

    ...mapGetters([
        'getObject'
    ])
  },

  watch: {
  },

  created() {
  },

  methods: {
    close() {
      this.emitter.emit('unmount', true)
    }
  },
};
</script>

<style scoped lang="scss">
#kr-developing-header {
  height: 64px;
  background: #cccccc;

  .btn {
    max-width: 200px;
    text-align: center;
    text-overflow: initial;
    overflow: initial;
    white-space: normal;
    line-height: 1.3rem;
    position: relative;

    &.btn-link {
      text-decoration: none;
    }

    .kr-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &.left {
      padding-left: 55px;

      span {
        left: 10px;
      }
    }

    &.right {
      padding-right: 45px;

      span {
        right: 10px;
      }
    }
  }

  .kr-developing-title {
    text-align: center;

    p {
      margin-bottom: 0;
    }
  }
}
</style>
