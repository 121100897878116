import { createStore } from "vuex"

export default createStore({
  state() {
    return {
      snippets: {},
      objects: {}
    }
  },

  mutations: {
    setSnippets(state, snippets) {
      state.snippets = snippets || {}
    },

    setObjects(state, objects) {
      state.objects = objects
    }
  },

  actions: {
    async loadData (context, { url }) {
      const objects = await (await fetch(url)).json()
      context.commit('setObjects', objects && objects.elements ? objects.elements : {})
    }
  },

  getters: {
    trans: (state) => (key, def = '') => {
      const sKeys = key.split('.')
      let value = state.snippets
      sKeys.forEach(sKey => value = (value[sKey] ?? {}))

      return typeof value === 'object' && Object.keys(value).length
        || typeof value === 'string' ? value : def
    },

    getObject: (state) => (objectId) => {
      return state.objects[objectId] || {}
    },

    getObjectLevels: (state) => (objectId) => {
      return ((state.objects[objectId] || {}).developmentObjectLevels || []).filter(item => item.active)
    },

    getObjectLevelByNumber: (state, getters) => (objectId, levelNumber) => {
      return (getters.getObjectLevels(objectId) || []).find(item => item.level === parseInt(levelNumber))
    },

    getObjectLevelApartments: (state, getters) => (objectId, levelId) => {
      return (((getters.getObjectLevels(objectId) || [])
        .find(level => level.id === levelId) || {}).developmentApartments || []).filter(item => item.active)
    },

    getObjectApartments: (state, getters) => (objectId) => {
      const objectLevels = getters.getObjectLevels(objectId) || []

      return objectLevels.reduce((result, level) => {
          return getters.getObjectLevelApartments(objectId, level.id);
        }, [])
    },
  }
})
