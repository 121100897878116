<template>
  <svg id="kr-svg" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" viewBox="0 0 1920 1080">
    <image class="kr-svg-object-image" width="1920" height="1080" v-bind:[getTag]="(getObject(projectId).cover || {}).url"></image>

    <g v-for="level in getObjectLevels(projectId)" :key="level.id">
      <polygon :points="level.svgPath" @click="$router.push({ name: 'LevelView', params: { projectId, levelNumber: level.level } })" />
    </g>
  </svg>
</template>

<script>
import { mapGetters } from "vuex"
// import svgPanZoom from 'svg-pan-zoom'

export default {
  name: 'ProjectView',

  props: ['projectId'],

  computed: {
    getTag() {
      return 'xlink:href'
    },

    ...mapGetters([
      'getObject',
      'getObjectLevels',
    ])
  },

  watch: {
  },

  // mounted() {
  //   setTimeout(() => {
  //     svgPanZoom('#kr-svg', {
  //       controlIconsEnabled: true,
  //       dblClickZoomEnabled: false,
  //       fit: false,
  //       contain: true,
  //       center: true,
  //       eventsListenerElement: document.querySelector('#kr-svg g')
  //     })
  //   }, 1500)
  // },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
path, polygon {
  fill: rgb(220, 220, 220);
  stroke: rgb(220, 220, 220);
  fill-opacity: 0.05;
  stroke-width: 2px;
  transition: .5s all;
  cursor: pointer;

  &:hover {
    fill: rgb(105, 105, 105);
    stroke: rgb(105, 105, 105);
    fill-opacity: 0.4;
  }
}
</style>
