<template>
  <svg id="kr-svg" width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1920 1358">
    <image class="kr-svg-object-image" width="1920" height="1358" v-bind:[getTag]="((getObjectLevelByNumber(projectId, levelNumber) || {}).media || {}).url"></image>

<!--    <g v-for="level in getObjectLevels(projectId)" :key="level.id">-->
<!--      <path :d="level.svgPath" />-->
<!--    </g>-->
  </svg>
</template>

<script>
import { mapGetters } from "vuex";
import svgPanZoom from "svg-pan-zoom";

export default {
  name: 'LevelView',

  props: ['projectId', 'levelNumber'],

  components: {
  },

  data() {
    return {
    };
  },

  computed: {
    getTag() {
      return 'xlink:href'
    },

    ...mapGetters([
      'getObjectLevelByNumber',
    ])
  },

  mounted() {
    svgPanZoom('#kr-svg', {
      controlIconsEnabled: true,
      dblClickZoomEnabled: false,
      fit: false,
      contain: false,
      center: true
    })
  },
};
</script>

<style lang="scss" scoped>
svg path {
  fill: rgb(220, 220, 220);
  stroke: rgb(220, 220, 220);
  fill-opacity: 0.05;
  stroke-width: 2px;
  transition: .5s all;
  cursor: pointer;

  &:hover {
    fill: rgb(105, 105, 105);
    stroke: rgb(105, 105, 105);
    fill-opacity: 0.4;
  }
}
</style>
