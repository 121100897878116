import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from "vue-router";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faArrowTurnDown } from '@fortawesome/free-solid-svg-icons'
import routes from './modules/routes'
import store from './modules/store'
import App from './App.vue'
import mitt from 'mitt';

// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
// import axios from 'axios';
// import vSelect from 'vue-select';
// import ToggleButton from 'vue-js-toggle-button';
// import vueSlider from 'vue-slider-component';
// import Vue2TouchEvents from 'vue2-touch-events';
// import 'babel-polyfill';

// Vue.use(ToggleButton);
// Vue.use(Vue2TouchEvents);
// Vue.component('v-select', vSelect);
// Vue.component('vue-slider', vueSlider);
// Vue.prototype.$http = axios;

// library.add(faUserSecret, faArrowTurnDown)

const globalMixin = {
  methods: {
    trans(key, def = '') {
      return this.$store.getters.trans(key, def);
    }
  }
}

window.krDevelopingSpa = {
  instance: null,

  init() {
    if (!this.instance) {
      this.instance = createApp(App)
      this.instance.use(createRouter({
        history: createWebHashHistory(),
        routes: routes,
      }))
        .use(store)
        .mixin(globalMixin)
        .component('font-awesome-icon', FontAwesomeIcon)
        .config.globalProperties.emitter = mitt()
    }
  },

  mount() {
    this.init()
    this.instance.mount('#kr-developing-spa')
  },

  unmount() {
    this.instance.unmount()
    this.instance = null
  }
}

